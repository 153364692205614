import "./index.scss";

const Arrow = () => {
  return (
    <svg className="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="-0.03 0 330.03 330.02">
      <path d="M315,0c-8.284,0-15,6.716-15,15v210H105v-60c0-6.067-3.655-11.537-9.26-13.858  c-5.606-2.323-12.058-1.039-16.347,3.252l-75,75c-5.858,5.858-5.858,15.355,0,21.213l75,75c2.87,2.87,6.705,4.394,10.61,4.394  c1.932,0,3.881-0.373,5.737-1.142c5.605-2.322,9.26-7.792,9.26-13.858v-60h210c8.284,0,15-6.716,15-15V15C330,6.716,323.284,0,315,0  z M75,278.787L36.214,240L75,201.213V278.787z" />
    </svg>
  );
};

export default Arrow;
